import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogTitle, Snackbar } from 'MaterialUIComponents';
import { ConfigService, IConfigServiceInjectedProps } from '$State/ConfigFreezerService';
import { DeviceAssociationService, IDeviceAssociationServiceInjectedProps } from '$State/DeviceAssociationFreezerService';
import { faTimes, faVideo } from '@fortawesome/pro-solid-svg-icons';
import { FilterBar, IFilterOptions } from '$Components/FilterComponents/FilterBar';
import { FilterBreadcrumbs } from '$Components/FilterComponents/FilterBreadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridListToggleButton } from '$Components/Shared/GridListToggleButton';
import { IIntegrationPartnerDataInjectedProps, IntegrationPartnerDataService } from '$State/IntegrationPartnerDataFreezerService';
import { IVideoEventServiceInjectedProps, VideoEventService } from '$State/VideoEventFreezerService';
import { IVideoRecallFilterServiceInjectedProps, VideoRecallFilterService } from '$State/VideoRecallEventFilterFreezerService';
import { IVideoRecallServiceInjectedProps, VideoRecallService } from '$State/VideoRecallFreezerService';
import { forEach } from 'lodash';
import { PageHeader } from '$Components/Shared/PageHeader';
import { React, _, bind, moment } from 'Imports';
import { RolesEnum, canEditByRole } from '$Externals/VerifyRole';
import { VideoEventCardList } from '$Components/VideoRecall/VideoEventCardList';
import { VideoEventList } from '$Components/VideoRecall/VideoEventList';
import { VideoEventResponse } from '$Generated/api';
import { VideoRecallRequestForm } from '$Components/VideoRecall/VideoRecallRequestForm';
import * as DateFormatter from '$Components/Shared/DateFormatter';

interface IVideoRecallBaseProps {
    onSelectVideoEvent: (value: string, goBack: boolean) => void;
    groupsFilter?: string;
    showEventId?: boolean;
}

type IVideoRecallProps = IVideoRecallBaseProps &
    IVideoRecallServiceInjectedProps &
    IIntegrationPartnerDataInjectedProps &
    IVideoRecallFilterServiceInjectedProps &
    IVideoEventServiceInjectedProps &
    IDeviceAssociationServiceInjectedProps &
    IConfigServiceInjectedProps;

interface IVideoRecallState {
    items: VideoEventResponse[];
    date: moment.Moment;
    dateText: string;
    timeframe: number;
    time: string;
    timeOfDay: 'am' | 'pm';
    showDatePicker: boolean;
    selectedVehicle: string[];
    showError: boolean;
    showFailure: boolean;
    showSuccess: boolean;
    filtersVisible: boolean;
    requestVideoVisible: boolean;
    isMobileView: boolean;
    showPromoteDialog: boolean;
    eventIdToPromote: number;
    snackOpen: boolean;
    snackMessage: string;
    interval: NodeJS.Timeout | undefined;
}

const styles = require('./VideoRecall.scss') as {
    main: string;
    requestControls: string;
    requestTitle: string;
    inputContainer: string;
    requestLabel: string;
    requestInput: string;
    requestDate: string;
    requestDatePicker: string;
    requestButton: string;
    contentContainer: string;
    viewTypeToggleContainer: string;
    viewTypeToggle: string;
    content: string;
    noVideos: string;
    filterContainer: string;
    filterContainerMobile: string;
    filterBar: string;
    searchButton: string;
    buttonContainer: string;
    showRequestButton: string;
    trayTitle: string;
    trayClose: string;
    activeToggleIcon: string;
    inactiveToggleIcon: string;
    activeButton: string;
    buttonIcon: string;
    dialog: string;
};

class _VideoRecallPage extends React.Component<IVideoRecallProps, IVideoRecallState> {
    filterContainerDiv: any;

    state: IVideoRecallState = {
        items: [],
        date: moment(),
        dateText: DateFormatter.date(moment()),
        timeframe: 10,
        time: '',
        timeOfDay: 'am',
        showDatePicker: false,
        showError: false,
        showSuccess: false,
        showFailure: false,
        selectedVehicle: [],
        filtersVisible: false,
        requestVideoVisible: false,
        isMobileView: false,
        showPromoteDialog: false,
        eventIdToPromote: 0,
        snackOpen: false,
        snackMessage: '',
        interval: undefined,
    };

    async componentDidMount(): Promise<void> {
        window.addEventListener('resize', this.onWindowResize);
        await this.getVideoRecalls();
        this.props.integrationPartnerData.getDevices();
        this.onWindowResize();
    }

    async getVideoRecalls(): Promise<void> {
        VideoRecallService.clearVideoRecallVideoEvents();
        this.props.videoRecallEventFilter.setPageNumber(1);

        const eventFilter = _.cloneDeep(this.props.videoRecallEventFilter.getVideoRecallEventFilter());

        eventFilter.groupsFilter = this.props.integrationPartnerData.getGroupsFilter();

        const prevVehicleFilter = eventFilter.vehicleId;

        eventFilter.vehicleId = [];

        const integrationVehicles = this.props.integrationPartnerData.getState().deviceResults;

        forEach(integrationVehicles.data, function (v) {
            if (prevVehicleFilter && prevVehicleFilter.indexOf(v.id) > -1) {
                eventFilter.vehicleId?.push(v.id);
            }
        });

        this.props.videoRecallEventFilter.updateFilterOptions(
            eventFilter.eventType,
            eventFilter.driverName,
            eventFilter.vehicleId,
            eventFilter.startDate,
            eventFilter.endDate,
            eventFilter.groupsFilter,
        );

        await this.props.videoRecall.getFilteredVideoRecalls(this.props.videoRecallEventFilter.getVideoRecallEventFilter());
        await this.getFullListOfSucessRecallIds();
    }

    async componentWillUnmount(): Promise<void> {
        window.removeEventListener('resize', this.onWindowResize);
        if (this.state.interval != undefined) {
            clearInterval(this.state.interval);
        }
    }

    @bind
    handleEventLayoutChange(e: any, value: 'tile' | 'list'): void {
        if (value != null) {
            VideoRecallService.clearVideoRecallVideoEvents();
            this.props.videoRecallEventFilter.setPageNumber(1);
            this.props.videoRecallEventFilter.setSortFields('date', false);
            const eventFilter = this.props.videoRecallEventFilter.getVideoRecallEventFilter();
            this.props.videoRecall.getFilteredVideoRecalls(eventFilter);
            this.props.videoRecall.setVideoRecallViewType(value);
        }
    }

    @bind
    async handleFilterEvents(filterOptions: IFilterOptions): Promise<void> {
        VideoRecallService.clearVideoRecallVideoEvents();
        this.props.videoRecallEventFilter.setPageNumber(1);
        this.props.videoRecallEventFilter.updateFilterOptions(
            filterOptions.eventType,
            filterOptions.driverName,
            filterOptions.vehicleId,
            filterOptions.startDate,
            filterOptions.endDate,
            IntegrationPartnerDataService.getGroupsFilter(),
        );

        const eventFilter = this.props.videoRecallEventFilter.getVideoRecallEventFilter();
        await this.props.videoRecall.getFilteredVideoRecalls(eventFilter);
        await this.getFullListOfSucessRecallIds();
    }

    @bind
    async getFullListOfSucessRecallIds(): Promise<number[]> {

        const recallFilter = {
            ...this.props.videoRecallEventFilter.getVideoRecallEventFilter(),
            eventTypes: [5],
            showVideoPipelineSuccessOnly: true,
        };

        return await this.props.videoEvents.getListOfEventIds(recallFilter);
    }

    @bind
    async gridSort(sortBy: string, sortAsc: boolean): Promise<void> {
        VideoRecallService.clearVideoRecallVideoEvents();
        this.props.videoRecallEventFilter.setPageNumber(1);
        this.props.videoRecallEventFilter.setSortFields(sortBy, sortAsc);
        const eventFilter = this.props.videoRecallEventFilter.getVideoRecallEventFilter();
        await this.props.videoRecall.getFilteredVideoRecalls(eventFilter);
    }

    @bind
    async _onPageNumberChanged(newPage: number): Promise<void> {
        this.props.videoRecallEventFilter.setPageNumber(newPage);
        const eventFilter = this.props.videoRecallEventFilter.getVideoRecallEventFilter();
        await this.props.videoRecall.getFilteredVideoRecalls(eventFilter);
    }

    @bind
    toggleFilters(): void {
        const { filtersVisible } = this.state;
        this.setState({
            filtersVisible: !filtersVisible,
            requestVideoVisible: false,
        });
    }

    @bind
    toggleRequestVideo(): void {
        const { requestVideoVisible } = this.state;
        this.setState({
            requestVideoVisible: !requestVideoVisible,
            filtersVisible: false,
        });
    }

    @bind
    async loadVideoEventsOnScroll(): Promise<void> {
        if (!this.props.videoRecall.getState().videoRecallResults.isFetching) {
            const eventFilter = this.props.videoRecallEventFilter.getVideoRecallEventFilter();
            const currentPage = eventFilter.currentPage;
            if (currentPage && currentPage !== undefined) {
                let newPage = currentPage;
                newPage++;
                setTimeout(async (): Promise<void> => {
                    await this._onPageNumberChanged(newPage);
                }, 1500);
            }
        }
    }

    @bind
    onWindowResize(): void {
        if (window.innerWidth < 1150) {
            this.setState({ isMobileView: true, filtersVisible: false });
        } else {
            this.setState({ isMobileView: false, filtersVisible: false });
        }
    }

    @bind
    async deleteVideoRecall(eventId: number): Promise<void> {
        const filter = this.props.videoRecallEventFilter.getVideoRecallEventFilter();
        await this.props.videoRecall.deleteVideoRecall(eventId);
        VideoRecallService.clearVideoRecallVideoEvents();
        await this.props.videoRecall.getFilteredVideoRecalls(filter);
        await this.getFullListOfSucessRecallIds();
    }

    @bind
    updateVideoEventWorkflowStatus(eventId: number | undefined): void {
        if (!canEditByRole([RolesEnum.videoEventEdit])) return;
        this.props.videoEvents.updateVideoEventWorkflowStatus(eventId, 'In_Progress');
    }

    @bind
    togglePromoteVideoPopup(eventId?: number) {
        const { showPromoteDialog } = this.state;
        this.setState({ showPromoteDialog: !showPromoteDialog, eventIdToPromote: eventId ? eventId : 0 });
    }

    @bind
    async promoteVideo(eventId: number): Promise<void> {
        await this.props.videoRecall.PromoteVideoRecall(eventId);
        //refresh the page
        VideoRecallService.clearVideoRecallVideoEvents();
        this.props.videoRecallEventFilter.setPageNumber(1);
        const eventFilter = this.props.videoRecallEventFilter.getVideoRecallEventFilter();
        this.togglePromoteVideoPopup();
        this.snackOpen();
        this.props.videoRecall.getFilteredVideoRecalls(eventFilter);
        await this.getFullListOfSucessRecallIds();
    }

    @bind
    snackOpen(): void {
        this.setState({ snackOpen: true, snackMessage: 'Video Event Promoted' });
    }

    @bind
    handleSnackClose(): void {
        this.setState({ snackOpen: false, snackMessage: '' });
    }

    @bind
    onChangeIncludeInactiveVehicles(includeInactives?: boolean) {
        this.props.videoRecall.setIncludeInactiveVehicles(includeInactives);
    }

    async componentDidUpdate(prevProps: Readonly<IVideoRecallProps>): Promise<void> {
        if (this.props.groupsFilter !== prevProps.groupsFilter) {
            await DeviceAssociationService.getDeviceAssociations();
            await IntegrationPartnerDataService.getDevices();
            await this.getVideoRecalls();
        }
    }

    checkPendings(data: any[]): void {
        const pendings = data.filter((i) => i.workflowStep != 'Success' && i.workflowStep != 'Failed').length;

        if (pendings > 0 && this.state.interval == undefined) {
            const interval = setInterval(async () => {
                const eventFilter = this.props.videoRecallEventFilter.getVideoRecallEventFilter();
                eventFilter.itemsPerPage = (eventFilter.currentPage ?? 1) * (eventFilter.itemsPerPage ?? 25);
                eventFilter.currentPage = 1;
                VideoRecallService.clearVideoRecallVideoEvents();
                await this.props.videoRecall.getFilteredVideoRecalls(eventFilter);
            }, 120000);
            this.setState({ interval });
        } else if (pendings == 0 && this.state.interval != undefined) {
            clearInterval(this.state.interval);
            this.setState({ interval: undefined });
        }
    }

    render(): JSX.Element {
        const freezer = this.props.videoRecall.getState();
        const {
            videoRecallResults: videoRecallResults,
            videoRecallResultsFinal: videoRecallResultsFinal,
            videoRecallViewType: videoRecallViewType,
        } = this.props.videoRecall.getState();

        const isTileView = videoRecallViewType === 'tile';
        const data = videoRecallResultsFinal.length > 0 ? videoRecallResultsFinal : [];

        this.checkPendings(data);

        const eventFilter = this.props.videoRecallEventFilter.getVideoRecallEventFilter();
        const hasMorePagesToLoad =
            !freezer.hasInfiniteScrollError && (videoRecallResults.data?.currentPage ?? 0) < (videoRecallResults.data?.totalPages ?? 0)
                ? true
                : false;

        // if shouldLoadNextPage is true, the first page had all cards filtered out for tile view on the first page,
        // but there is more on the next page. We want to auto load the next page in this situation.
        const shouldLoadNextPage = isTileView && data.length === 0 && hasMorePagesToLoad;

        return (
            <div className={styles.main} id="videoRecallPage">
                <Snackbar
                    anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                    open={this.state.snackOpen}
                    onClose={this.handleSnackClose}
                    autoHideDuration={4000}
                    style={{ position: 'absolute' }}
                >
                    <Alert elevation={24} variant="filled" onClose={this.handleSnackClose} severity="success" color="success">
                        {this.state.snackMessage}
                    </Alert>
                </Snackbar>
                <PageHeader pageTitle={'Video Recall'} onSelectVideoEvent={this.props.onSelectVideoEvent} />
                <div className={styles.contentContainer}>
                    <div
                        className={this.state.isMobileView ? styles.filterContainerMobile : styles.filterContainer}
                        ref={(ref) => (this.filterContainerDiv = ref)}
                    >
                        {this.state.isMobileView && (
                            <div className={styles.buttonContainer}>
                                <Button
                                    aria-label="request"
                                    onClick={this.toggleRequestVideo}
                                    className={[styles.showRequestButton, this.state.requestVideoVisible ? styles.activeButton : ''].join(
                                        ' ',
                                    )}
                                >
                                    Request <FontAwesomeIcon icon={faVideo} className={styles.buttonIcon} />
                                </Button>
                            </div>
                        )}
                        {this.state.isMobileView ? (
                            <div className={styles.buttonContainer}>
                                <Button
                                    aria-label="search"
                                    onClick={this.toggleFilters}
                                    className={[styles.searchButton, this.state.filtersVisible ? styles.activeButton : ''].join(' ')}
                                >
                                    Filters
                                </Button>
                            </div>
                        ) : (
                            <FilterBar
                                hideEventType={true}
                                hideDrivers={true}
                                onFilterOptionsChange={this.handleFilterEvents}
                                eventFilter={eventFilter}
                                isMobileView={false}
                                isVideoRecallPage={true}
                                includeInactiveVehicles={this.props.videoRecall.getIncludeInactiveVehicles()}
                                onChangeIncludeInactiveVehicles={this.onChangeIncludeInactiveVehicles}
                            />
                        )}
                        <GridListToggleButton
                            handleEventLayoutChange={(e, value) => this.handleEventLayoutChange(e, value)}
                            videoEventViewType={videoRecallViewType}
                        />
                    </div>
                    <div className={styles.content}>
                        {this.state.requestVideoVisible &&
                            (this.state.isMobileView ? (
                                <>
                                    <VideoRecallRequestForm toggleRequestVideo={this.toggleRequestVideo} isMobileView={true} />
                                </>
                            ) : (
                                <></>
                            ))}
                        {this.state.filtersVisible && (
                            <div className={styles.filterBar}>
                                <FontAwesomeIcon icon={faTimes} className={styles.trayClose} onClick={this.toggleFilters} />
                                <div className={styles.trayTitle}>Filter Videos</div>
                                <FilterBar
                                    hideEventType={true}
                                    hideDrivers={true}
                                    onFilterOptionsChange={this.handleFilterEvents}
                                    eventFilter={eventFilter}
                                    isMobileView={true}
                                    isVideoRecallPage={true}
                                    includeInactiveVehicles={this.props.videoRecall.getIncludeInactiveVehicles()}
                                    onChangeIncludeInactiveVehicles={this.onChangeIncludeInactiveVehicles}
                                />
                            </div>
                        )}
                        {!this.state.isMobileView && (
                            <>
                                <VideoRecallRequestForm
                                    toggleRequestVideo={(): void => {
                                        /*Do Nothing*/
                                    }}
                                    isMobileView={false}
                                />
                            </>
                        )}
                        {
                            <div hidden={data.length === 0 && !shouldLoadNextPage}>
                                {this.state.isMobileView && (
                                    <FilterBreadcrumbs filters={eventFilter} onUpdateFilters={this.handleFilterEvents} />
                                )}
                                {videoRecallViewType === 'tile' ? (
                                    <VideoEventCardList
                                        items={data}
                                        promoteVideo={this.togglePromoteVideoPopup}
                                        onSelectVideoEvent={this.props.onSelectVideoEvent}
                                        loadNextPage={this.loadVideoEventsOnScroll}
                                        morePagesToLoad={hasMorePagesToLoad}
                                        deleteVideoRecall={this.deleteVideoRecall}
                                        updateVideoEventWorkflowStatus={this.updateVideoEventWorkflowStatus}
                                    />
                                ) : (
                                    <VideoEventList
                                        items={data}
                                        onGridSort={this.gridSort}
                                        onSelectVideoEvent={this.props.onSelectVideoEvent}
                                        loadNextPage={this.loadVideoEventsOnScroll}
                                        promoteVideo={this.togglePromoteVideoPopup}
                                        deleteVideoRecall={this.deleteVideoRecall}
                                        morePagesToLoad={hasMorePagesToLoad}
                                        showEventId={this.props.showEventId}
                                    />
                                )}
                            </div>
                        }
                        {data.length === 0 && !shouldLoadNextPage && (
                            <div className={styles.noVideos}>
                                {this.props.videoRecall.getVideosLoaded() ? (
                                    <div>No Recalled Video for the criteria selected</div>
                                ) : (
                                    <CircularProgress></CircularProgress>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <Dialog open={this.state.showPromoteDialog} className={styles.dialog}>
                    <DialogTitle>Promote Video Recall?</DialogTitle>
                    <DialogActions>
                        <Button onClick={() => this.promoteVideo(this.state.eventIdToPromote)}>Continue</Button>
                        <Button onClick={() => this.togglePromoteVideoPopup()}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export const VideoRecallPage = VideoEventService.inject(
    VideoRecallFilterService.inject(
        IntegrationPartnerDataService.inject(
            DeviceAssociationService.inject(ConfigService.inject(VideoRecallService.inject(_VideoRecallPage))),
        ),
    ),
);
