import { DetailsSection } from '../../../VideoEventDetails/DetailsSection';
import { Grid } from '../../../../../MaterialUIComponents';
import { PlayAll } from '../PlayAll';
import { React } from '../../../../../Imports';
import { VideoEventResponse } from '$Generated/api';
import { VideoRecallDetailsSection } from './VideoRecallDetailsSection';
import { VideoEventPrevNextSection } from '$Components/VideoEvents/VideoEventDetails/VideoEventPrevNextSection';

const styles = require('./VideoRecallSidePanel.scss') as {
    cardContent: string;
    responsiveGrid: string;
};

interface IRecallSidePanelBaseProps {
    anyVideosPlaying: boolean;
    event: VideoEventResponse;
    eventStatus: string | undefined;
    onPlayButtonClick: () => void;
    prevNextSplitOn: boolean;
    videoCount: number;
    width: any;
    onSelectVideoEvent: (eventId: string, goBack: boolean) => void;
}
type IRecallSidePanelProps = IRecallSidePanelBaseProps;

const _VideoRecallSidePanel: React.FC<IRecallSidePanelProps> = ({
    anyVideosPlaying,
    event,
    eventStatus,
    onPlayButtonClick,
    prevNextSplitOn,
    videoCount,
    width,
    onSelectVideoEvent,
}) => {
    return (
        <div className={styles.cardContent}>
            {prevNextSplitOn ? (
                <VideoEventPrevNextSection eventId={event.displayId} onSelectVideoEvent={onSelectVideoEvent} isRecall={true}/>
            ) : (
                <PlayAll videoCount={videoCount} event={event} onPlayButtonClick={onPlayButtonClick} anyVideosPlaying={anyVideosPlaying} />
            )}
            <DetailsSection width={width}>
                <Grid key={`details-section-video-recall-details-section`} className={styles.responsiveGrid} item sm={12} md={6} lg={12}>
                    <VideoRecallDetailsSection event={event} eventStatus={eventStatus} prevNextSplitOn={prevNextSplitOn} />
                </Grid>
            </DetailsSection>
        </div>
    );
};

export const VideoRecallSidePanel = _VideoRecallSidePanel;
